$main-color: #ff9689;
$highlight-color: #75deb9;
$sub-color: #4cd4a4;
$pink: #ffaaea;
$skyblue: #55e6ff;
$red: #ff7171;
$green:#78ff71;
$orange: #ffb171;
$purple: #b871ff;
$yellow: #ffdc71;
$blue: #399fff;
$lightgreen: #b8ff71;
$back-color: rgba(255, 240, 240, 0.5);
// $back-color: white;
$font-color: #4c4c4c;

// $breakpoints: (
//   sm: 576,
//   md: 860,
// );

// @each $size, $value in $breakpoints {
//   :export {
//     $size: $value;
//   }
// }
