@import './variables';
@import './settings';

.body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;

  .suspense {
    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 10vw;
      height: 10vw;
    }
  }

  a {
    color: $sub-color;
    text-decoration: none;
  }

  .date {
    font-size: 0.85rem;
    color: gray;
  }

  .sub_font {
    font-family: “Quicksand”, “Avenir”, “Arial”, “BIZ UDGothic”,
      “Hiragino Kaku Gothic ProN”, YuGothic, “Yu Gothic”, “Hiragino Sans”,
      “ヒラギノ角ゴシック”, “メイリオ”, Meiryo, sans-serif;
  }

  .headline {
    position: relative;
    top: 6vw;
    left: 2vw;
    z-index: 3;
    width: 38vw;
    height: auto;
    margin-top: -5vw;

    @media screen and (max-width: 859px) {
      top: 4rem;
      width: 22rem;
      margin-top: -2rem;
    }
    @media screen and (max-width: 576px) {
      top: 3.5rem;
      width: 60vw;
    }
  }

  header {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 20;
    // margin-bottom: 1vw;
    background: rgba(255, 255, 255, 0);

    .header {
      display: flex;
      min-height: 64px;
      // padding: 1.5vw 0 1vw 0;
      padding: 1rem 0;
      margin: 0 2vw;
      @media screen and (max-width: 576px) {
        min-height: 50px;
        height: 50px;
        padding: 0.7rem 0.5rem;
      }

      .header_nav {
        margin-left: auto;

        .nav {
          margin-left: 16px;
          font-weight: bold;
        }

        a {
          color: $main-color;
          vertical-align: middle;
        }

        button {
          color: white;
          background-color: $main-color;
        }
      }

      img {
        width: 20vw;
        height: auto;
        max-width: 150px;
        min-width: 120px;
        max-height: 64px;
        vertical-align: top;
        @media screen and (max-width: 576px) {
          max-height: calc(50px - 1.4rem);
          max-width: calc((150 * (50px - 1.4rem)) / 64);
          min-width: 80px;
        }
      }
    }
  }

  .container {
    flex: 1;
    background: $back-color;

    .MuiLinearProgress-colorPrimary {
      color: $main-color;
      background-color: #fbdad6;
    }

    .MuiLinearProgress-barColorPrimary {
      background-color: $main-color;
    }

    .flex-container {
      display: flex;
      padding: 6vh 4vw;

      main {
        flex: 1;
        min-width: 0;
      }

      aside {
        display: flex;
        flex-direction: column;
        width: 300px;
        padding: 0 10px;
      }
    }

    @media screen and (max-width: 1023px) {
      .extend-padding {
        padding: 6vh 0;
      }
    }

    .aside-bottom-content {
      padding: 2rem;
      background: white;

      .relation_grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        justify-content: center;
      }
    }
  }

  footer {
    background: white;
    // background: #ff97894a;
    // border-top: 1px solid $main-color;

    .footer {
      display: flex;
      justify-content: space-between;
      max-width: 100%;
      min-height: 64px;
      padding: 3rem 6vw;

      .footer-links {
        display: flex;

        p {
          font-weight: bold;
        }

        ul {
          padding: 0;

          li {
            padding: 0.4rem 0;
            list-style: none;

            a {
              color: #606060;
            }
          }
        }

        .footer-sns {
          margin-left: 4vw;
        }
      }

      .footer-message {
        margin-left: 80px;
        text-align: center;

        img {
          width: 28vw;
          height: auto;
          max-width: 200px;
          min-width: 128px;
          max-height: 87px;
        }

        p {
          font-size: 0.5rem;
        }
      }
    }
  }

  .fusen {
    display: inline-block;
    padding: 10px 25px 10px 15px;
    margin: 15px 0;
    font-size: 0.95rem;
    font-weight: bold;
    line-height: 1.25rem;
    letter-spacing: 0;
    background: #fff;
    border-left: 20px solid $main-color;
    box-shadow: 2px 2px 5px 1px #f3f3f3;
    transform: rotate(-2deg);
  }

  .simple_box {
    padding: 1vw 2vw;
    margin: 0 1vw;
    font-size: 0.95rem;
    border: solid 2px $main-color;
    border-radius: 4px;
  }

  .summary_box {
    margin: 1rem 0.5rem;
    border: solid 2px $main-color;

    .box_title {
      position: relative;
      top: -2px;
      left: -2px;
      display: inline-block;
      padding: 2px 9px;
      font-size: 0.9rem;
      font-weight: bold;
      color: #fff;
      background: $main-color;
    }

    .box_content {
      padding: 1rem;
      padding-top: 0.5rem;

      @media screen and (max-width: 576px) {
        font-size: 0.9rem;
      }
    }
  }

  .chapter {
    padding: 0.2rem 1.5rem;
    font-size: 1.3em;
    font-weight: bold;
    border-left: 4px solid $main-color;
  }

  .hint {
    position: relative;
    padding: 21px 15px 21px 45px;
    margin: 1.5rem 0;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.65);
    background: #fff6e4;
    border-radius: 6px;

    &::before {
      position: absolute;
      top: 50%;
      left: 15px;
      display: block;
      width: 20px;
      height: 20px;
      font-size: 13px;
      font-weight: 700;
      line-height: 20px;
      color: #fff;
      text-align: center;
      content: '!';
      background: #ffb84c;
      border-radius: 50%;
      transform: translateY(-52%);
    }
  }

  .hint.warn {
    background: #ffeff2;

    &::before {
      background: #ff7670;
    }
  }

  .hint.error {
    background: #f7f7f7;

    &::before {
      display: none;
    }
  }
}
