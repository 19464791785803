@import '/src/settings';
@import '/src/variables';

main {
  @media screen and (max-width: 859px) {
    .header_image {
      width: 100vw;
      overflow: hidden;
      // border: 1px solid rgba(64, 87, 109, 0.07);
      // border-radius: 4px;
      margin: -6vh 0;
      // margin-top: calc(-6vh - 64px - 2rem);

      img {
        width: 100%;
        height: auto;
        display: 'block';
      }
    }
  }
}

.article_container {
  position: relative;
  // padding: 3vw 0;
  padding: 6vw;
  margin: 0 2vw;
  background-color: white;
  @media screen and (max-width: 859px) {
    margin-top: -15vw;
    border-radius: 10px;
    padding: 6vw 4vw;
    margin: 0 1.5vw;
  }

  .link_card {
    width: 80%;
    $link-height: 7rem;
    height: $link-height;
    margin: 2rem auto;
    overflow: hidden;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 12px;
    @media screen and (max-width: 1023px) {
      height: 5rem;
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }

    .link_card_back {
      height: 100%;
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 4px;
      &:hover {
        transform: scale(1.1);
        transition: 0.5s all;
      }

      .p_link {
        width: 100%;
        line-height: $link_height;
        text-align: center;
        margin: 0;
        font-weight: bold;
        color: whitesmoke;
        background: rgba(103, 103, 103, 0.5);
        font-size: 1.3rem;
        border-radius: 4px;
        @media screen and (max-width: 1023px) {
          line-height: 5rem;
        }
        @media screen and (max-width: 576px) {
          font-size: 1.1rem;
        }
        &:hover {
          opacity: 0;
          transition: 0.5s all;
        }
        &::after {
          font-family: 'Font Awesome 5 Free', sans-serif;
          content: '\f35d';
        }
      }
    }
  }

  .link_card2 {
    width: 90%;
    $link-height: 7rem;
    height: $link-height;
    margin: 2rem auto;
    overflow: hidden;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 12px;
    a {
      display: flex;
      align-items: center;
      &:hover {
        text-decoration: none;
      }

      .link_card_back {
        height: $link-height;
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        flex: 1;
      }
      .link_card_title {
        width: 60%;
        padding-left: 1rem;
        padding-right: 7px;
        p {
          margin: 0;
          font-weight: bold;
          color: #5c5c5c;
          font-size: 1rem;
          @media screen and (max-width: 1023px) {
            font-size: 0.8rem;
          }
          @media screen and (max-width: 576px) {
            font-size: 1rem;
          }
        }
        .p_link_des {
          color: #6c6c6c;
          font-size: 0.7rem;
          @media screen and (max-width: 1023px) {
            margin-top: 5px;
            font-size: 0.4rem;
          }
          @media screen and (max-width: 576px) {
            display: none;
          }
        }
      }
    }
  }

  .genre_icon_group {
    @include icon_position;
    right: -1vw;

    .genre_icon {
      @include genre_icon;
    }
  }

  .article_tags {
    @include article-tags;

    margin-top: 0;
    margin-bottom: 3rem;
  }

  .welcome_content {
    .welcome_table_of_content {
      @include table-of-content-container;
      @include table-of-content-list;

      h2 {
        @include table-of-content-title;

        margin: 0;
      }
    }

    .welcome_image {
      padding: 1vw;
      margin: 20px 1vw;
      border: 2px solid lightgray;
    }

    .icon_grid {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      gap: 2vw;
      padding: 15px;
      margin: 20px 1vw;
      border: 2px solid lightgray;

      .flex {
        display: flex;

        .genre_icon {
          @include genre_icon;
        }

        p {
          height: 30px;
          padding-left: 2vw;
          margin: 0;
          line-height: 30px;

          @media screen and (max-width: 576px) {
            font-size: 0.6rem;
          }
        }
      }

      .last-grid {
        grid-row: 5;
        grid-column: 1 / span 2;
      }
    }

    .twitter_container {
      margin-top: 3rem;

      .followme_header {
        position: relative;
        top: 20px;
        left: -1.5rem;
        z-index: 1;
        width: 9rem;
        height: auto;
        margin-top: -20px;
      }

      .twitter_button {
        position: relative;
        z-index: 2;
        display: block;
        width: 80px;
        margin: 0 auto;
        text-align: center;

        &:hover {
          text-decoration: none;
        }

        .twitter_id {
          position: relative;
          top: -5px;
          font-size: 0.8rem;
          color: $sub-color;
        }
      }
    }
  }

  .option {
    display: flex;
    margin-bottom: 2vw;

    .date_container {
      margin-left: auto;
    }

    .location {
      font-size: 0.85rem;
    }
  }

  .article_header {
    position: relative;
    margin-bottom: 2rem;

    .md-title {
      margin: 3rem 0 0.7rem 0;
      padding: 0.25em 0.5em;
      border-left: solid 5px $main-color;
      @media screen and (min-width: 1024px) {
        font-size: 2.5vw;
      }
      @media screen and (max-width: 859px) {
        margin: 3rem 0 2rem 0;
      }
    }

    .ribbon14-wrapper {
      position: relative;
      box-sizing: border-box;
      display: block;
      padding: 0.5rem 4rem 0.5rem 2rem;
      margin: 15px auto;
      margin-bottom: 0;
      background: #f7f7f7;
      @media screen and (max-width: 859px) {
        padding-right: 3.2rem;
      }

      .ribbon14 {
        position: absolute;
        top: 0;
        right: 10px;
        z-index: 2;
        display: inline-block;
        width: 3rem;
        padding: 0.8rem 0;
        margin: 0;
        font-size: 1.2rem;
        color: white;
        text-align: center;
        background: $main-color;
        border-bottom: 0.2px solid $main-color;
        @media screen and (max-width: 859px) {
          width: 25px;
          font-size: 1rem;
        }

        &::after {
          position: absolute;
          top: 100%;
          left: 0;
          width: 0;
          height: 0;
          content: '';
          border-right: 1.5rem solid $main-color;
          border-bottom: 0.75rem solid transparent;
          border-left: 1.5rem solid $main-color;
          @media screen and (max-width: 859px) {
            border-right: 12.5px solid $main-color;
            border-bottom: 0.5rem solid transparent;
            border-left: 12.5px solid $main-color;
          }
        }
      }

      p {
        color: #5f5f5f;
        font-size: 1.02rem;
        font-weight: bold;
      }
      @media screen and (min-width: 1024px) {
        padding: 1rem 4rem 1rem 2rem;
      }
    }

    .header_image {
      width: 100%;
      overflow: hidden;
      // border: 1px solid rgba(64, 87, 109, 0.07);
      // border-radius: 4px;

      img {
        width: 100%;
        height: auto;
        display: 'block';
      }
    }

    .title_container {
      position: absolute;
      top: 60%;
      right: 0;
      width: calc(((100% - 264px) / 12) * 6 + 102px);
      padding: 1vw;
      padding-right: 0;
      overflow: hidden;
      background: white;
      border-radius: 8px 0 0 8px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);

      &::before {
        display: block;
        padding-top: 50%;
        content: '';
      }

      .article_title {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0 2vw;
        padding-right: 0;

        .title {
          margin: 0;
          font-size: 1.8vw;
          font-weight: bold;
        }

        .overview {
          font-size: 0.8vw;
          color: gray;
        }
      }
    }
  }

  .share_button_group {
    margin-top: 10vh;
  }
}

.article-footer {
  width: 100%;
  padding: 4vw 6vw;
  padding-bottom: 7vw;
  margin: 0 auto;
  background: white;

  .article-info {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    font-size: 1rem;
    border-bottom: 1px solid lightgray;
    -webkit-box-align: center;

    .info-header {
      width: 100px;
      font-weight: bold;
      color: $main-color;
    }

    .info-data {
      flex: 1;
      color: gray;

      .tag {
        margin: 1px 2px;
        color: $sub-color;
        border: 1px solid $sub-color;
      }
    }
  }

  .relation {
    margin-top: 40px;
    margin-bottom: 0;
    background: white;

    .relation_grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      justify-content: center;
    }
  }
}
